import {DateTime} from 'luxon'
import {
  IAppointmentSerializedData,
  IGCAppointmentFormDataComplete,
  IGCAppointmentResponse,
  IGeneticCounselingAppointment,
} from 'models/GeneticCounseling'
import {ensureSpaceInName} from 'services/StringUtils'
/* eslint-disable spellcheck/spell-checker */
export const serialize = (
  geneticCounselingAppointmentFormData: IGCAppointmentFormDataComplete,
): IAppointmentSerializedData => {
  const {
    appointmentTypeId,
    counselor,
    date,
    patient: {
      additionalAttendees = '',
      additionalComments = '',
      additionalQuestions = '',
      email = '',
      interpreterInfo = '',
      name = '',
      resultRecipients = [],
      rqNumberList = '',
      smsReminderConsent = false,
    } = {},
    phone,
    province,
    reqid,
    timezone,
    consent,
  } = geneticCounselingAppointmentFormData
  const validatedFullName = ensureSpaceInName(name)
  return {
    appointmentTime: DateTime.fromMillis(date).setZone(timezone).toISO({suppressMilliseconds: true}),
    appointmentTypeId,
    calendarId: counselor,
    patient: {
      additionalAttendees,
      additionalComments,
      additionalQuestions,
      consentForCounseling: consent,
      email,
      interpreterInfo,
      name: validatedFullName,
      resultRecipients,
      rqNumberList,
      smsReminderConsent,
    },
    phone,
    province,
    reqid,
    timezone,
  }
}

export const deserializeAppointment = (response: IGCAppointmentResponse): IGeneticCounselingAppointment => ({
  calendarId: response.calendar_id.toString(),
  datetime: response.appointment_time,
  phone: response.phone,
  timezone: response.timezone,
})
/* eslint-enable */
