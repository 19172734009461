import {IReport, IResultServiceVariant, ResultVariants} from 'models/Report'

import {IAsyncModel} from './AsyncModel'
import {PaymentStatus, PaymentTypes} from './Payment'

export enum OrderTypes {
  carrier = 'Carrier',
  combiPOC = 'Pregnancy Loss',
  diagnostic = 'Diagnostic',
  exome = 'Exome',
  exomePreventiveAddon = 'Exome Preventive Addon',
  nips = 'NIPS',
  pgx = 'Pharmacogenomics',
  preventive = 'Preventive',
  statTat = 'STAT-TAT',
}

export enum ReportLevelStatuses {
  positive = 'positive',
  negative = 'negative',
  increasedRisk = 'increased_risk',
  carrier = 'carrier',
  potentiallyPositive = 'potentially_positive',
  indeterminate = 'indeterminate',
  negativePartialResult = 'negative_partial_result',
}

export const LEARN_MORE_ENABLED_REPORT_STATUSES = [
  ReportLevelStatuses.positive,
  ReportLevelStatuses.potentiallyPositive,
  ReportLevelStatuses.increasedRisk,
  ReportLevelStatuses.carrier,
]

export enum RequisitionAttachmentCategories {
  ALL_PAPERWORK = 'all_paperwork',
  FAMILY_HISTORY = 'family_history',
  PREVIOUS_RESULTS = 'previous_results',
  INSURANCE_CARD = 'insurance_card',
  SEC_INSURANCE_CARD = 'sec_insurance_card',
  INVOICE = 'invoice',
  LETTER_OF_MEDICAL_NECESSITY = 'letter_of_medical_necessity',
  PAP_FAP_APPLICATION = 'pap_fap_application',
  PATIENT_MEDICAL_RECORD = 'patient_medical_record',
  REPORT = 'report',
  REPORT_COVER_SHEET = 'report_cover_sheet',
  SCANNED_REQ_FORM = 'scanned_req_form',
  SAMPLE_TUBE_SCAN = 'sample_tube_scan',
  WAIVER = 'waiver',
  INSURANCE_PAPERWORK = 'insurance_paperwork',
  GENETIC_COUNSELING = 'genetic_counseling',
  CREDIT_CARD_AUTHORIZATION_FORM = 'credit_card_authorization_form',
  COLLABORATION_FORM = 'collaboration_form',
  MEDICAL_RECORDS = 'medical_records',
  ONLINE_REQUISITION_FORM = 'online_requisition_form',
  OTHER = 'other',
  SAMPLE_FAILURE_LETTER = 'sample_failure_letter',
  PAYMENT_RECEIPT = 'payment_receipt',
  GIA_RESULTS_TRANSCRIPT = 'gia_results_transcript',
  GIA_TRANSCRIPT = 'gia_transcript',
  RESIDUAL_RISK_TABLE = 'residual_risk_table',
}

export const attachmentCategoriesToLabels = {
  [RequisitionAttachmentCategories.ALL_PAPERWORK]: 'All Paperwork',
  [RequisitionAttachmentCategories.FAMILY_HISTORY]: 'Family History / Pedigree',
  [RequisitionAttachmentCategories.PREVIOUS_RESULTS]: 'Previous Results',
  [RequisitionAttachmentCategories.INSURANCE_CARD]: 'Primary Insurance Card',
  [RequisitionAttachmentCategories.SEC_INSURANCE_CARD]: 'Secondary Insurance Card',
  [RequisitionAttachmentCategories.INVOICE]: 'Invoice',
  [RequisitionAttachmentCategories.LETTER_OF_MEDICAL_NECESSITY]: 'Letter of Medical Necessity',
  [RequisitionAttachmentCategories.PAP_FAP_APPLICATION]: 'PAP Application',
  [RequisitionAttachmentCategories.PATIENT_MEDICAL_RECORD]: 'Patient Medical Record',
  [RequisitionAttachmentCategories.REPORT]: 'Report (posts to portal)',
  [RequisitionAttachmentCategories.REPORT_COVER_SHEET]: 'Report Cover Sheet',
  [RequisitionAttachmentCategories.SCANNED_REQ_FORM]: 'Scanned Requisition Form',
  [RequisitionAttachmentCategories.SAMPLE_TUBE_SCAN]: 'Sample Tube Scan',
  [RequisitionAttachmentCategories.WAIVER]: 'Waiver (NY, etc)',
  [RequisitionAttachmentCategories.INSURANCE_PAPERWORK]: 'Insurance Paperwork',
  [RequisitionAttachmentCategories.GENETIC_COUNSELING]: 'Genetic Counseling docs (posts to patient portal)',
  [RequisitionAttachmentCategories.CREDIT_CARD_AUTHORIZATION_FORM]: 'Credit Card Authorization Form',
  [RequisitionAttachmentCategories.COLLABORATION_FORM]: 'Collaboration Form',
  [RequisitionAttachmentCategories.MEDICAL_RECORDS]: 'Medical Records',
  [RequisitionAttachmentCategories.ONLINE_REQUISITION_FORM]: 'Online requisition form',
  [RequisitionAttachmentCategories.OTHER]: 'Other',
  [RequisitionAttachmentCategories.SAMPLE_FAILURE_LETTER]: 'Sample Failure Letter',
  [RequisitionAttachmentCategories.PAYMENT_RECEIPT]: 'Payment Receipt',
  [RequisitionAttachmentCategories.GIA_RESULTS_TRANSCRIPT]: 'Gia Results Transcript',
  [RequisitionAttachmentCategories.GIA_TRANSCRIPT]: 'Gia Transcript',
  [RequisitionAttachmentCategories.RESIDUAL_RISK_TABLE]: 'Carrier Residual Risk Table',
}

export enum RequisitionConceptCategoryType {
  main_category = 'main_category',
  primary_commercial_area = 'primary_commercial_area',
  secondary_commercial_area = 'secondary_commercial_area',
  order_group = 'order_group',
  sub_category = 'sub_category',
}

export interface IRequisitionConceptCategory {
  code: string
  concept_type: RequisitionConceptCategoryType
  display_name: string
  name: string
  order: number | null
}

export interface IRequisitionConcept {
  aggregation_type: string
  all_gene_names: string[]
  categories: IRequisitionConceptCategory[]
  concept_code: string
  concept_description: string
  concept_name: string
  concept_ontology: string
  total_genes_count: number
}

export interface IRequisitionAttachment {
  id: number
  date_created: string
  original_filename: string
  note: string
  is_downloaded: boolean
  category: RequisitionAttachmentCategories
}

export interface IRequisitionReport extends IRequisitionAttachment {
  results_released_to_patient: boolean
  patient_has_requested_results: boolean
  report_release: {
    patient_requested_results_date: string
    results_released_to_patient_date: string
    scheduled_release_date: string
  }
  report_data?: IReport
  report_status?: string
  requisition?: IRequisition
}

export interface IRequisitionStage {
  name: string
  startedAt: string
  isActivated: boolean
  hasWarning?: boolean
}

interface IRequisitionContentBlock {
  next: string | string[]
  reducedNext: string
  blurb: string
  request?: string
  scheduledRequest?: string
  title: string
  startedTitle?: string
  reducedDescription?: string
  possibleResult?: string
  blurbHeader?: string
}

export interface IRequisitionContent {
  [key: string]: IRequisitionContentBlock
}

export interface IRequisitionReceipt {
  discount: string
  amount: string
  testPrice: number
  datePaid: string
  lastNumbers: string
  cardBrand?: string
}

export enum PioTag {
  FormsSubmitted = 'forms submitted',
  KitActivated = 'kit activated',
  KitPurchased = 'kit purchased',
  KitShipped = 'kit shipped',
  OrderApproved = 'order approved',
}

export enum PeTag {
  HolisticBillingGovernmentPayor = 'hb_government_payor',
  NoHolisticBillingGovernmentPayor = 'no_hb_government_payor',
}

export interface IPartnershipInfo {
  code: string
  display_name: string
  landing_page_url?: string
}

export interface IRequisition {
  can_patient_request_results_immediately?: boolean
  reqid: string
  parent_reqid?: string
  patient_id: string
  patient_name: string
  patient_country: string
  is_gc_scheduler_eligible: boolean
  is_re_requisition: boolean
  is_tier_one_billing_report?: boolean
  is_canceled: boolean
  is_patient_initiated: boolean
  is_hereditary_cancer_category: boolean
  isSparkPartnership: boolean
  isCardiologyCategory: boolean
  isHereditaryCancerCategory: boolean
  isOphthalmologyCategory: boolean
  isBTSPartnership: boolean
  isANDPartnership: boolean
  isChronicPancreatitisPanel: boolean
  isHearingLossPanel: boolean
  isNeurologyPanel: boolean
  isOrderedByGenomeMedical: boolean
  isOutOfCriteria: boolean
  isPregnancyLossOrderGroup: boolean
  isPrenatalChromosomalMicroarrayAnalysis: boolean
  cancelled_at: string
  has_reports_released: boolean
  has_sample_failure: boolean
  results_released_to_patient: boolean
  patient_has_requested_results: boolean
  most_recent_released_report: boolean
  most_recent_report_released_date: number
  ordering_clinician_full_name: string
  orderingClinicianCountry?: string
  practice_country: string | null
  active_stage_name: string
  date_created: string
  type: string
  payment_type: PaymentTypes
  order_type: OrderTypes
  concepts: IRequisitionConcept[]
  stages: IRequisitionStage[]
  reports: IRequisitionReport[]
  documents: IRequisitionAttachment[]
  attachments: IRequisitionAttachment[]
  unreadAttachmentsCount: number
  result_is_positive?: boolean
  report_status?: ReportLevelStatuses
  indication: string
  family_history: string
  previous_results: string
  isPrintable?: boolean
  isVisible?: boolean
  isReducedStages?: boolean
  isAtReview?: boolean
  isCarrier?: boolean
  isProactive?: boolean
  isDiagnostic?: boolean
  isPatientAmerican?: boolean
  isOrderingClinicianFromUsaCanadaOrPuertoRico?: boolean
  isNips?: boolean
  isExome?: boolean
  isPGx?: boolean
  isCombiPOC?: boolean
  isPatientInitiated?: boolean
  stepsCompleted?: PioTag[]
  hasSummaryResults?: boolean
  displayingReports?: IRequisitionReport[]
  coreDisplayingReports?: IRequisitionReport[]
  showUnderstandingYourResultsModal?: boolean
  estimatedAmount?: string
  estimatedAmountLowerBound?: string
  estimatedAmountUpperBound?: string
  isOOPNotApplicable?: boolean
  patientSelectedPaymentTypeAt?: string
  paymentSelectionExpiration?: string
  selectedPaymentType?: PaymentTypes
  selectedPaymentAt?: string
  receipts?: IRequisitionReceipt[]
  trackNumber?: string
  deliveryService?: string
  shouldDisplayTrackLink: boolean
  variants?: ResultVariants
  resultServiceVariants?: IResultServiceVariant[]
  preReportSimplifiedVariants?: IResultServiceVariant[]
  patientPrice?: string
  patientPaymentNotificationsEnabled?: boolean
  patientPaymentStatus?: PaymentStatus
  patientIsPartner?: boolean
  partnershipInfo?: IPartnershipInfo
  bundledRequisitions?: string[]
  tags?: string[]
  title: string
  hasPwnOnHold?: boolean
  hasGCDismiss?: boolean
  patient_state?: string
  patient_phone?: string
  has_sex_reveal_video_watched?: boolean
  isSendoutNipsOrder?: boolean | null
  hasPatientViewedResults?: boolean
}

export type TRequisitions = IRequisition[]

export interface IAsyncRequisitions extends IAsyncModel<TRequisitions> {
  loadedAt?: Date
  requested?: string
  isLoadingVariants?: boolean
  isLoadingReceipts?: boolean
}

export interface ISelectRequisitionProps {
  match: {
    params: {
      reqid: string
    }
  }
}

export enum KitTypes {
  buccal = 'buccal',
  saliva = 'saliva',
}
