// When you add new feature flag - please update this doc file so it will be easier to track FF status
// https://docs.google.com/spreadsheets/d/1U7kHCKQ_meLM-NFzL2r4D_5NQMLz4T-sTdGOIs9DoWg
export const FEATURE_FLAGS_NAMES = [
  'cx_pe_test_registration_errors_update', // https://invitae.jira.com/browse/CEJD-267
  'cx_pe_deprecate_invite_partner_carrier', // https://invitae.jira.com/browse/CEJD-592
  'cx_pe_read_and_write_to_pds_through_stargate', // https://invitae.jira.com/browse/CEJD-33
  'cx_pe_deprecate_reproductive_gc_scheduler', // https://invitae.jira.com/browse/DHPE-1628
  'cx_pe_patient_pay_estimates', // https://invitae.jira.com/browse/CELT-2367
  'cx_genetic_risk_assessment', // https://invitae.jira.com/browse/CELT-2408
  'cx_pe_remove_inheritance', // https://invitae.jira.com/browse/DHPE-3643
  'cx_pe_gc_scheduler_consent', // https://invitae.jira.com/browse/DHPE-3619
  'cx_pe_gc_scheduler_intake_labcorp', // https://invitae.jira.com/browse/DHPE-3618
  'cx_pe_requisition_tab_billing', // https://invitae.jira.com/browse/CELT-2437
] as const

export type FEATURE_FLAG_NAME = (typeof FEATURE_FLAGS_NAMES)[number]

export const TOGGLE_PROJECT_NAME = 'customer-experience'
export const FEATURE_FLAG_APP_NAME = 'patient-experience'
